@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

.graph {
  margin: 3rem;
  @include responsive(tab) {
    margin: 5rem;
  }
  @include responsive(large-screen) {
    margin: 7rem;
  }
}

#graph a {
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: $dark-blue;
  cursor: pointer;
  box-shadow: $shadow;
  border: 1px solid $ash;
  text-align: center;
  background-color: #EEEEEE !important;
  @include responsive(tab) {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    text-align: center;
  }
}

#graph a:hover {
  min-width: 9%;
  text-align: center;
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  box-shadow:  0.1rem 1rem 2rem rgba(0,0,0,0.2);
  border: 1px solid $ash;
  display: 1px solid #0f0;
  background: linear-gradient(to right bottom, $light-blue, $dark-blue);
  cursor: pointer;
  color: $white;
  @include responsive(tab) {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    text-align: center;
  }
}


#graph button {
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: $dark-blue;
  cursor: pointer;
  box-shadow: $shadow;
  border: 1px solid $ash;
  text-align: center;
  background-color: #EEEEEE !important;
  @include responsive(tab) {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    text-align: center;
  }
}

// #graph button:hover {
//   min-width: 9%;
//   text-align: center;
//   font-size: 1.2rem;
//   text-decoration: none;
//   padding: 0.5rem 1rem;
//   box-shadow:  0.1rem 1rem 2rem rgba(0,0,0,0.2);
//   border: 1px solid $ash;
//   display: 1px solid #0f0;
//   background: linear-gradient(to right bottom, $light-blue, $dark-blue);
//   cursor: pointer;
//   color: $white;

//   @include responsive(tab) {
//     font-size: 1.5rem;
//     padding: 1rem 2rem;
//     text-align: center;
//   }
// }