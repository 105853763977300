@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/flexPoint.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

.form-wrapper {
  width: 90%;
  min-height: 25vh;
  background: $white;
  border-radius: 0.5rem;
  box-shadow: $shadow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem;
  margin: 12rem 0rem;

  @include responsive(tab){
    margin: 15rem 0rem;
    width: 70%;
    padding: 1rem 5rem;
  }
  @include responsive(desktop){
    width: 50%;
    padding: 4rem 4rem;
  }
  @include responsive(large-screen) {
    width: 30%;
    padding: 4rem 4rem;
    margin: 20rem 0rem;
  }
  label {
    padding-bottom: 2rem;
  }
  .input-btn {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    .form-control {
      border: 1px solid #0f0;
      width: 80%;
    }
    input {
      width: 100%;
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 300;
      border: 1px solid #ddd;
      border-radius: 0.5rem 0 0 0.5rem;
      @include responsive(tab) {
        padding: 1.5rem;
      }
      @include responsive(large-screen) {
        padding: 2.5rem;
        font-size: 1.5rem;
      }
    }
    input::placeholder {
      @include responsive(large-screen) {
        font-size: 2.5rem;
      }
    }
    input:focus {
      outline: none;
      border: 0.05rem solid rgba(91, 192, 222, 1);
      box-shadow: 0.1rem 0.2rem 2rem rgba(0,0,0,0.2);
    }
    .upload-btn {
      width: 10%;
      padding: 1.1rem 3rem;
      cursor: pointer;
      background: linear-gradient(to right bottom, $light-blue, $dark-blue);
      color: $white;
      border: none;
      border-radius: 0 0.3rem 0.3rem 0;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      @include responsive(tab) {
        width: 20%;
        padding: 1.4rem;
        font-size: 1.8rem;
      }
      @include responsive(large-screen) {
        width: 20%;
        padding: 2.5rem;
      }
    }
    .upload-btn:focus {
      outline: none;
      opacity: 0.8;
      cursor: auto;
    }
  }
}

label {
  align-items: left;
  display: block;
  font-size: 1.5rem;
  @include responsive(tab) {
    font-size: 2rem;
  }
  @include responsive(large-screen) {
    font-size: 3rem;
  }
}

.json-input {
  color: rgba(201, 0, 0, 0.822);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  @include responsive(tab){
    font-size: 2.5rem;
    margin-bottom: 0;
  }
}
.json-input:hover {
  cursor: pointer;
}
.json-input:focus {
  outline: none;
}