@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';
@import '../../assets/stylesheets/flexPoint.scss';

.wrapper {
  width: 100%;
  height: auto;
  padding-bottom: 2rem;
  @include flexPoint(column, space-evenly);
  @include responsive(desktop) {
    min-height: 100vh;
  }
  .profile {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    display: flex;
    justify-content: flex-end;
    .user {
      border-radius: 50%;
      border: none;
      font-size: 12rem;
      color: $white;
      box-shadow: $shadow;
      @include responsive(desktop) {
        font-size: 15rem;
      }
      width: 10%;
    }
    .user:hover {
      cursor: pointer;
    }
  }
  .metrics {
    text-align: center;
    width: 100%;
    height: auto;
    padding: 1rem;
    @include flexPoint(column, space-evenly);
    @include responsive(tab) {
      @include flexPoint(row-reverse, space-evenly);
    }
  }
  .metrics > div {
    height: auto;
    padding: 1rem;
    margin: 0.5rem  0;
    border-radius: 0.5rem;
    text-align: left;
    h2 {
      font-size: 1.5rem;
      @include responsive(tab) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 0.8rem;
    }
    @include responsive(tab) {
      width: 30%;
      min-height: 25vh;
      margin: 0 0.5rem;
      padding: 2rem;
    }
    @include responsive(large-screen) {
      min-height: 25vh;
      margin: 0 0.5rem;
      padding: 2rem;
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 2rem;
        font-weight: 300;
      }
    }
   
  }
  .metrics > div:hover {
    cursor: pointer;
    transition: all 1s ease-in-out;
  }
  .projects {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 5rem 0;
    .project {
      box-shadow: $shadow;
      width: 50%;
      min-height: 20vh;
      font-weight: 300;
      height: auto;
      margin: 1.5rem 0;
      border-radius: 0.5rem;
      background: $white;
      
      @include responsive(tab) {
        width: 30%;
        p {
          font-size: 1.35rem;
          line-height: 1.7;
        }
      }
      @include responsive(desktop) {
        p {
          font-size: 1.35rem;
          line-height: 1.7;
        }
      }
      @include responsive(large-screen) {
        width: 45%;
        padding: 1.5rem;
        div {
          font-size: 3.5rem;
        }
        p {
          font-size: 3.5rem;
          line-height: 2;
        }
      }
    }
    .project:hover {
      cursor: pointer;
    }
    .project a {
      display: flex;
      justify-content: space-between;
      align-items:  center;
      text-decoration: none;
      color: inherit;
      min-height: 20vh;
      padding: 1rem;
    }
  }
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.beta-tag {
  margin-left: -5% !important;
}