@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

.Form-wrapper {
  width: 90%;
  height: auto;
  border-radius: 0.5rem;
  background: $white;
  box-shadow: 0.1rem 0.2rem 2rem #ddd;
  margin: 2rem 0;
  @include responsive(tab) {
    width: 70%;
  }
  @include responsive(desktop) {
    width: 50%;
  }

  h1,h2,h3,h4 {
    margin-bottom: 1rem;
    text-align: center;
  }

  .form {
    width: 90%;
    margin: 0 auto;
    padding: 1rem;
    input {
      width: 100%;
      margin: 1rem 0;
      padding: 1rem;
      border: 1px solid #ddd;
      border-radius: 0.3rem;
      font-size: medium;
      @include responsive(tab){
        padding: 1.5rem;
      }
    }
    input:focus {
      outline: none;
      border: 0.05rem solid rgba(91, 192, 222, 1);
      box-shadow: 0.1rem 0.2rem 2rem rgba(0,0,0,0.2);
    }
    select {
      width: 100%;
      margin: 1rem 0;
      padding: 1rem;
      border: 1px solid #ddd;
      color: inherit;
      font-size: 1.5rem;
      border-radius: 0.3rem;
      outline: none;
      option {
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 300;
        margin: 1rem 0;
        padding: 1rem;
      }
    }
    select:hover {
      cursor: pointer;
    }
    input[type=submit] {
      width: 40%;
      margin: 0 auto;
      cursor: pointer;
      background: linear-gradient(to right bottom, $light-blue, $dark-blue);
      color: $white;
      border: none;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      @include responsive(tab){
        width: 30%;
      }
      @include responsive(desktop){
        width: 20%;
      }
    }
    .key-section {
      width: 100%;
      margin: 0 auto;

      @include responsive(tab) {
        width: 80%;
      }
      .key-header {
        width: 50%;
        border-bottom: 1px solid #000;
        padding: 0.5rem 1rem;
      }
      .key-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .key-btn {
        width: 8rem;
        height: 4rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border: 0.1rem solid $light-blue;
        color: $dark-blue;
        border-radius: 0.3rem;
        margin-left: 0.5rem;
        @include responsive(tab){
          height: 5rem;
        }
      }
      .key-btn:hover {
        color: $white;
        background: linear-gradient(to right bottom, $light-blue, $dark-blue);
      }
      .key-btn:focus {
        outline: none;
      }
      .key-container {
        .key-ordered-list {
          font-size: 1.5rem;
          padding: 0.25rem 0.5rem;
          line-height: 1.7;
          @include responsive(tab){
            font-size: 2rem;
            padding: 0.5rem 1rem;
            line-height: 1.7;
          }
        }
        .key-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: $dirty-white;
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          padding: 0rem 0.5rem;
          line-height: normal;
          @include responsive(tab){
            padding: 0.5rem 1rem;
          }
          p {
            display: inline-block;
            font-weight: 300;
          }
          .icon-buttons {
            .button {
              display: inline-block;
              margin-right: 2rem;
              border: none;
              background: none;
              @include responsive(tab){
                font-size: 1.5rem;
              }
            }
            .button:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}