@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

html {
  scroll-behavior: smooth;
}
.footer {
  background: #4374FC;
  color: $white;
  width: 100%;
  display: flex;
  border-radius: 100px 0px 0px 0px;
  text-decoration: none;

  @include responsive(tab) {
    border-radius: 150px 0px 0px 0px;
   font-size: 2rem;
  }

  @include responsive(large-screen) {
   font-size: 5rem;
  }

  .logo-area {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    @include responsive(large-screen) {
      font-size: 3rem;
    }
    h1 {
      padding: 1rem;
      text-align: center;
      @include responsive(tab) {
        font-size: 2.5rem;
      }
      @include responsive(desktop) {
        font-size: 3rem;
      }
      @include responsive(large-screen) {
        font-size: 5rem;
      }
    }
    .soc-icon {
      a {
        font-size: 1.3rem;
        margin: 0 0.5rem;
        @include responsive(tab) {
          font-size: 1.5rem;
          margin: 0 1.5rem;
        }
        @include responsive(large-screen) {
          font-size: 5rem;
          margin: 0 1.5rem;
        }
      }
    }
  }
  .links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    .link-company {
      padding: 1rem;
      ul > li {
        list-style: none;
        text-align: center;
        margin: 1rem 0;
      }
    }
  }
  a {
    text-decoration: none;
    color: $white;
  }
  a:hover {
    cursor: pointer;
    color: $dirty-white;
    opacity: 0.8;
  }
  .arrow-up {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color: #000;
    text-align: center;
    width: 3rem;
    height: 3rem;
    position: fixed;
    bottom: 3rem;
    right: 1rem;
    z-index: 3;
    border-radius: 0.5rem;
    background: $ash;
    box-shadow: $shadow;
    transform: scale(1);
    animation: goUp 2s ease-in-out,
               bounce 0.8s infinite alternate ease-in-out 2.5s;
    -webkit-animation: goUp 2s ease-in-out,
                       bounce 0.8s infinite alternate ease-in-out 2.5s;
    @include responsive(tab) {
      right: 3rem;
      width: 5rem;
      height: 5rem;
    }
    @include responsive(large-screen) {
      right: 5rem;
      width: 7rem;
      height: 7rem;
    }
  }
  .arrow-up:hover {
    color: #000;
  }

  @keyframes goUp {
    0% {
      bottom: 80rem;
      transform: scale(1.1);
    }
    50% {
      bottom: 1rem;
    }
    100% {
      bottom: 3rem;
      transform: scale(1);
    }
  }
  @keyframes bounce {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.links-div {
  width: 50%;
  a {
    margin: 2%;
  }
  font-size: small;
  display: flex;
  justify-content: center;
}