@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

.aside-wrapper {
  margin: 5rem 0; 
  @include responsive(tab) {
    margin: 10rem 0;
  }
}

.aside {
  width: 90vw;
  min-height: 25vh;
  height: auto;
  padding: 5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background: $white;
  box-shadow: $shadow;

  .div-aside {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include responsive(tab) {
    width: 50vw;
  }
  .upload-btn {
    padding: 1rem 2rem;
    background: linear-gradient(to bottom, $light-blue, $dark-blue);
    color: $white;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    cursor: pointer;
    border: none;
    outline: none;
    @include responsive(tab) {
      padding: 1rem 2rem;
      font-size: 1.5rem;
    }
    @include responsive(desktop) {
      padding: 1rem 2rem;
    }
  }
  .filename {
    width: 100%;
    height: 2rem;
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  .remove-file {
    padding: 1.6rem 2rem;
    background: rgba(255,99,71,1);
    color: $white;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    cursor: pointer;
    border: none;
    outline: none;
    @include responsive(tab) {
      padding: 1.6rem 2rem;
      font-size: 1.5rem;
    }
    @include responsive(desktop) {
      padding: 1.6rem 2rem;
    }
  }
}