@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';
@import '../../assets/stylesheets/flexPoint.scss';

.sign-up-message {
  font-size: 1rem;
  padding: 1.5rem 3rem;
  border: 1px solid $ash;
  border-left: 5px solid $orange;
  border-radius: 0.3rem;
  box-shadow: $shadow;
  @include responsive(tab) {
    font-size: 1.2rem;
  }
}
.sign-form {
  background: $white;
  width: 90%;
  height: auto;
  margin: 2rem 0;
  padding: 2rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0.1rem 0.2rem 2rem #ddd;
  @include flexPoint(column, center);
  @include responsive(tab){
    width: 50%;
  }
  @include responsive(desktop){
    width: 40%;
  }
  @include responsive(large-screen){
    width: 30%;
  }
  .sign-icon {
    width: 8rem;
    height: 8rem;
    padding: 1.5rem;
    border-radius: 50%;
    background: #ccc; //  rgba(256,20,20,1);
  }
  .form-control {
    width: 80%;
    margin: 0rem 2rem;
    padding: 1rem;
    label {
      align-items: left;
      display: block;
      font-size: 1.5rem;
    }
      input {
        width: 100%;
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: 300;
        border: 1px solid #ddd;
        border-radius: 0.5rem;
        @include responsive(tab){
          padding: 1.5rem;
        }
      }
      input:focus {
        outline: none;
        border: 0.05rem solid rgba(91, 192, 222, 1);
        box-shadow: 0.1rem 0.2rem 2rem rgba(0,0,0,0.2);
      }
  }
  .btn {
    width: 50%;
    margin: 1rem auto;
    padding: 1rem 2rem;
    cursor: pointer;
    background: linear-gradient(to right bottom, $light-blue, $dark-blue);
    color: $white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    @include responsive(tab){
      width: 40%;
    }
    @include responsive(large-screen){
      width: 20%;
    }
  }
  .btn:focus {
    outline: none;
    opacity: 0.8;
    cursor: auto;
  }
  .redirect-signin {
    width: 78%;
    font-size: 1rem;
    text-align: left;
    margin: 0 auto;
    padding: 0.3rem 1rem;
    // border: 1px solid chartreuse;
    a {
      text-decoration: none !important;
      color: $dark-blue;
    }
  }
  .dont {
    padding-bottom: 2rem;
  }
}