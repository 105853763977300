@import '../../assets/stylesheets/utils.scss';
.btn-loader {
  border: 0.2rem solid #e3e3e3;
  border-radius: 50%;
  border-top: 0.2rem solid $dark-blue;
  width: 2rem;
  height: 2rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}