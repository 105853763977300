@import '../../assets/stylesheets/utils.scss';
@import '../../assets/stylesheets/mediaQuery.scss';

.error-wrapper {
  width: 90%;
  min-height: 70vh;
  text-align: center;
  .err-header {
    font-size: 2rem;
    padding: 2rem 0;
    @include responsive(tab) {
      font-size: 5rem;
    }
  }
  .error-img {
    width: 25rem;
    height: 50vh;
    @include responsive(tab) {
      width: 50rem;
      height: 50vh;
    }
  }
  .back-home {
    font-size: 1.5rem;
    padding: 2rem 0;
    a {
      color: $light-blue;
      text-decoration: none;
    }
  }
}