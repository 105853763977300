@import '../../assets/stylesheets/mediaQuery.scss';
@import '../../assets/stylesheets/flexPoint.scss';
@import '../../assets/stylesheets/utils.scss';

.header-container {
  background: linear-gradient(to bottom, $light-blue, $dark-blue);
  width: 100%;
  min-height: auto;
  color: $white;
  padding: 1.5rem 0;
  font-size: 1.5rem;
  @include flexPoint(row, space-between);

  @include responsive(desktop) {
    width:100%;
   font-size: 2rem;
   height: 7vh;
  }
  .link {
    text-decoration: none;
    color: $white;
  }
  .link:hover {
    opacity: 0.8;
  }
  .navbar-brand {
    font-size: 1.5rem;
    padding: 0 0.5rem;
    @include responsive(tab) {
      font-size: 2rem;
      padding: 0 1.5rem;
    }
    @include responsive(large-screen) {
      font-size: 5rem;
      padding: 0 2rem;
    }
  }
  .navbar-brand:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  .nav {
    .btn {
      color: $light-blue;
      padding: 0.5rem 0.5rem;
      background: $white;
      border-radius: 0.5rem;
      text-decoration: none;
      font-size: 1rem;
      @include responsive(tab) {
        padding: 0.5rem 2rem;
        font-size: 1.5rem;
      }
      @include responsive(desktop) {
        padding: 0.5rem 2rem;
        font-size: 2rem;
      }
    }
    .plus {
      font-size: 1rem;
      @include responsive(tab) {
        font-size: 1.5rem;
      }
      @include responsive(desktop) {
        font-size: 2rem;
      }
    }
    .link {
      color: $white;
      font-size: 1rem;
      font-weight: 300;
      padding: 0 0.5rem;
      @include responsive(tab) {
        font-size: 2rem;
        padding: 0 1.5rem;
      }
      @include responsive(large-screen) {
        font-size: 3.5rem;
      }
    }
  }
}
  