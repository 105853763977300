@import '../../assets/stylesheets/flexPoint.scss';
@import '../../assets/stylesheets/mediaQuery.scss';
@import '../../assets/stylesheets/utils.scss';
.main-wrapper {
  box-sizing: border-box;
  font-family: 'Miriam Libre', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // border: 1px solid yellow;
  .banner-wrapper {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include responsive(tab) {
      flex-direction: row;
    }
  }
  .banner-img {
    width: 98%;
    height: 50vh;
    @include responsive(tab) {
      width: 50%;
      height: 80%;
    }
  }
  .text-block {
    width: 100%;
    min-height: 50vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include responsive(tab) {
      width: 50%;
      height: 80vh;
    }
    @include responsive(large-screen) {
      min-height: 90vh;
    }

    h2 {
      width: 80%;
      text-align: center;
      font-size: 2rem;
      padding: 1.5rem;
      font-weight: 700;
      line-height: 1.7;
      @include responsive(tab) {
        font-size: 3rem;
      }
      @include responsive(large-screen) {
        width: 100%;
        font-size: 7rem;
      }
    }
    .text-description {
      width: 80%;
      text-align: center;
      font-size: 1.5rem;
      line-height: 2;
      font-weight: 400;
      padding-bottom: 1rem;
      @include responsive(large-screen) {
        font-size: 5rem;
        line-height: 1.7;
      }
    }
  }
  .content-wrapper {
    width: 98%;
    height: auto;
    // border: 1px solid #0f0;
    text-align: center;
    .content-header {
      font-size: 2rem;
      font-weight: bolder;
      width: 80%;
      margin: 2rem auto;
      padding: 4rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include responsive(tab) {
        width: 70%;
        font-size: 4rem;
        line-height: 5.4rem;
      }
      @include responsive(large-screen) {
        margin: 4rem auto;
        width: 50%;
        font-size: 6rem;
        line-height: 6.4rem;
      }
    }
    .three-tiers {
      // border: 1px solid #ff0;
      height: auto;
      width: 95%;
      margin: 0 auto;

      .tier {
        // border: 1px solid yellow;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 1rem 2rem 1rem;
        @include responsive(tab) {
          flex-direction: row;
          justify-content: space-evenly;
        }
        .tier-img {
          width: 100%;
          height: 30rem;
          // border: 1px solid #00f;
          @include responsive(tab) {
            width: 50%;
          }
        }
        .tier-text {
          width: 100%;
          text-align: left;
          // padding: 0 1rem;
          @include responsive(tab) {
            width: 50%;
          }
          h2 {
            display: inline-block;
            font-size: 2.5rem;
            // border: 1px solid purple;
            @include responsive(tab) {
              width: 60%;
            }
            @include responsive(large-screen) {
              font-size: 5rem;
            }
          }
          p {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2;
            // border: 1px solid purple;
            @include responsive(large-screen) {
              font-size: 3rem;
              line-height: 2;
            }
          }
        }
      }
      .tier-2 {
        flex-direction: column;
        @include responsive(tab) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}