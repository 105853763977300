@import '../../assets/stylesheets/mediaQuery.scss';
@import '../../assets/stylesheets/utils.scss';
.welcome-wrapper {
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2rem auto 5rem;
  font-size: 1.5rem;
  // border: 1px solid chartreuse;
  @include responsive(tab) {
    height: 50vh;
  }
  .welcome-img {
    width: 100%;
    height: 30vh;
    // border: 1px solid red;
    @include responsive(tab) {
      height: 50vh;
      // font-size: 1.5rem;
    }
    @include responsive(large-screen) {
      height: 50vh;
      // font-size: 1.5rem;
    }
    
  }

  h3 {
    font-weight: 400;
    font-family: 'Miriam Libre', sans-serif;
    font-size: 1.2rem;
    @include responsive(tab) {
      font-size: 1.5rem;
    }
    @include responsive(tab) {
      font-size: 2rem;
    }
    @include responsive(large-screen) {
      font-size: 5rem;
    }
  }
}

.verified {
  font-weight: 300;
  font-size: 1.5rem;
  @include responsive(tab) {
    font-size: 2rem;
  }
  @include responsive(large-screen) {
    font-size: 5rem;
  }
}